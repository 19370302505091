import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import Arrow from "@/components/Icons/Arrow";
import DoubleArrow from "@/components/Icons/DoubleArrow";
import useKeyboardNav from "@/hooks/useKeyboardNav";
import { useLocale } from "@/hooks/useLocales";
import config from "@/lib/config";
import { selectInlineNav } from "@/state/selectors/general";
import { selectManualSwitch } from "@/state/selectors/general";

const scrollModule = config.height;

export default function StepsMenu({ goTo }) {
  const chapterBeginning = useLocale({ id: "restartChapter" });
  const manualSwitch = useSelector(selectManualSwitch, manualSwitch);
  const { hasNext, hasPrev } = useSelector(selectInlineNav);

  function goNext(e) {
    e.stopPropagation();
    goTo(scrollModule, "smooth", "scrollBy");
  }

  function goPrev(e) {
    e.stopPropagation();
    goTo(-scrollModule, "smooth", "scrollBy");
  }

  function restartChapter(e) {
    e.stopPropagation();
    goTo(0, "auto");
  }

  useKeyboardNav({ goPrev, goNext });

  return (
    <>
      <div className="steps-menu">
        <motion.div
          className="steps-menu__btn"
          data-active={hasPrev}
          whileTap={{ scale: 0.8 }}
          onPointerDown={goPrev}
        >
          <Arrow direction="up" />
        </motion.div>

        <motion.div
          className="steps-menu__btn"
          data-active={hasNext}
          whileTap={{ scale: 0.8 }}
          onPointerDown={goNext}
        >
          <Arrow direction="down" />
        </motion.div>
      </div>

      <div
        onPointerDown={restartChapter}
        className="restart-station"
        data-active={!hasNext}
      >
        <DoubleArrow direction="up" />
        {chapterBeginning}
      </div>
    </>
  );
}
