import { memo } from "react";

import QrCode from "@/components/QrCode";
import StepContent from "@/components/StepContent";
import StepMedia from "@/components/StepMedia";

function Step({ step, index, scrollY }) {
  return (
    <div className="step-content relative">
      <StepContent {...{ step, scrollY, index }} />
      <StepMedia {...{ step }} />
      <QrCode {...{ step }} />
    </div>
  );
}

export default memo(Step);
