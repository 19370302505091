export default function QuoteMark({ className }) {
  return (
    <svg
      className={className}
      width="142"
      height="114"
      viewBox="0 0 284 227"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M88.0274 162C113.027 125 119.027 105 119.027 78V-8.22544e-06H12.0273V107H82.0274L0.0273468 198L45.0273 227L88.0274 162ZM252.027 162C277.027 125 283.027 105 283.027 78V-8.22544e-06H176.027V107H246.027L164.027 198L209.027 227L252.027 162Z"
        fill="currentColor"
        fillOpacity="0.4"
      />
    </svg>
  );
}
