import { motion } from "framer-motion";

export default function Close({
  className = "text-tc-secondary h-16",
  onClick = () => {},
}) {
  return (
    <motion.svg
      className={className}
      viewBox="0 0 109 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      whileTap={{ scale: 0.8 }}
    >
      <rect width="109" height="100.926" fill="black" />
      <rect y="-0.000976562" width="109" height="100.926" fill="black" />
      <line
        y1="-2.5"
        x2="74.5232"
        y2="-2.5"
        transform="matrix(-0.711188 -0.703002 0.711188 -0.703002 81.2109 76.4648)"
        stroke="currentColor"
        strokeWidth="5"
      />
      <line
        x1="80.9787"
        y1="24.842"
        x2="26.9787"
        y2="78.842"
        stroke="currentColor"
        strokeWidth="5"
      />
    </motion.svg>
  );
}
