if (process.env.MODE !== "web") {
  require("dotenv").config({ path: ".env.app.local" });
}

const nodeEnv = process.env.NODE_ENV;
const mode = process.env.MODE;
const appId = process.env.APP_ID || "paulskirche-wechselaustellung";
const deviceId = process.env.DEVICE_ID || "stations--01";

const defaultStationId = parseInt(deviceId.split("--").pop()) || 1;

// const contentFetchEnabled = process.env.MODE === "web";
const contentFetchEnabled = true;

const cmsLocalHost = process.env.LOCAL_SERVER_PATH || "http://localhost:3008";
const localDataRoute = "data";

const cmsHost =
  process.env.CMS_HOST || "https://directus.paulskirche.meso.design";
const apiRoute =
  process.env.API_ROUTE || "https://api.paulskirche.meso.design/api/exhibit";
const websiteUrl = process.env.WEBSITE_URL || "https://paulskirche.de";

const cmsApiToken = process.env.CMS_API_TOKEN;

const width = parseInt(process.env.WIDTH) || 3840;
const height = parseInt(process.env.HEIGHT) || 2160;
const idleTimeout = parseInt(process.env.IDLE_TIMEOUT) || 300;

const mqttEnabled = process.env.MQTT_ENABLED === "true";
const mesoConnectConfigUrl =
  process.env.MESO_CONNECT_CONFIG_URL || "meso-connect-config.json";
const brokerUsername = process.env.BROKER_USERNAME || "";
const brokerPassword = process.env.BROKER_PASSWORD || "";
const brokerOverride = {
  protocol: process.env.BROKER_PROTOCOL,
  address: process.env.BROKER_ADDRESS,
  port: parseInt(process.env.BROKER_PORT, 10),
};
const mqttSubscribe = {
  firstProp: `${deviceId}/first-node/first-prop`,
};
const mqttPublish = {};

module.exports = {
  nodeEnv,
  mode,
  deviceId,
  defaultStationId,
  appId,
  contentFetchEnabled,
  cmsHost,
  websiteUrl,
  apiRoute,
  cmsLocalHost,
  localDataRoute,
  cmsApiToken,
  width,
  height,
  idleTimeout,
  mqttEnabled,
  mesoConnectConfigUrl,
  brokerUsername,
  brokerPassword,
  brokerOverride,
  mqttSubscribe,
  mqttPublish,
};
