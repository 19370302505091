import { useSelector } from "react-redux";

import LoadingScreen from "@/components/Loading/LoadingScreen";
import StationWrapper from "@/components/StationWrapper";

export default function App() {
  const dataLoadingState = useSelector(
    (state) => state.general.dataLoadingState,
  );

  return (
    <>
      {dataLoadingState === "pending" ? <LoadingScreen /> : <StationWrapper />}
    </>
  );
}
