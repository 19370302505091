export default function FilterMedia() {
  const { r, g, b } = {
    r: 1,
    g: 0.94,
    b: 0.89,
  };

  return (
    <svg className="def-filter">
      <filter
        id="customfilter"
        x="-10%"
        y="-10%"
        width="120%"
        height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feComponentTransfer in="SourceGraphic" result="componentTransfer">
          <feFuncR type="table" tableValues={`0 ${r}`} />
          <feFuncG type="table" tableValues={`0 ${g}`} />
          <feFuncB type="table" tableValues={`0 ${b}`} />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
        <feBlend
          mode="normal"
          in="componentTransfer"
          in2="SourceGraphic"
          result="blend"
        />
      </filter>
    </svg>
  );
}
