import config from "./config";

const { contentFetchEnabled, cmsHost, websiteUrl, cmsLocalHost } = config;

export const getAssetUrl = (id) => {
  const host = contentFetchEnabled ? cmsHost : cmsLocalHost;
  return `${host}/assets/${id}?key=4k-optimized`;
};

export const getStepQrCode = (locale, station, step, qrcode_url) => {
  const { slideImage, slideAudio, chapterID, id } = step;
  const standalone = !slideImage;

  if (qrcode_url) return { url: qrcode_url, standalone };
  if (slideAudio) {
    return {
      url: [
        websiteUrl,
        locale.split("-").shift(),
        "qr",
        station.id,
        chapterID,
        id,
      ].join("/"),
      standalone,
    };
  }
  return {};
};

export const getMediaType = (asset) => {
  if (!asset) return null;
  const { directus_files_id } = asset;
  if (!directus_files_id) return null;
  const { type } = directus_files_id;
  return type.split("/").shift();
};
