import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import useTranslations from "@/hooks/useTranslations";
import { getStepQrCode } from "@/lib/assets";
import {
  selectCurrentStation,
  selectLanguage,
} from "@/state/selectors/general";

export const useStepQr = ({ step }) => {
  const { qrcode_url } = useTranslations(step);

  const station = useSelector(selectCurrentStation, shallowEqual);
  const locale = useSelector(selectLanguage, shallowEqual);

  const qr = useMemo(
    () => getStepQrCode(locale, station, step, qrcode_url),
    [locale, station, step, qrcode_url],
  );

  return qr;
};
