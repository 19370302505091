import { memo } from "react";
import QRCode from "react-qr-code";

import QrIcon from "@/components/Icons/Qr";
import { useLocale } from "@/hooks/useLocales";
import { useStepQr } from "@/hooks/useMediaProperties";
import { useIsCurrentStep, useParamSelector } from "@/state/selectors/general";

function QrCode({ step }) {
  const { url, standalone } = useStepQr({ step });
  const qrText = useLocale({ id: "qrText" });

  const active = useParamSelector(useIsCurrentStep, {
    step: step.id,
  });

  if (!url || !active) return <></>;

  console.log("QR URL", url);

  if (standalone)
    return (
      <div className="absolute inset-0">
        <QrIcon className="w-1/4 absolute top-1/4 right-1/4" />

        <QRCode
          className="absolute"
          level="L"
          size={200}
          value={url}
          bgColor="#fff"
          fgColor="#000"
          style={{
            right: "44%",
            top: "48%",
          }}
        />
      </div>
    );

  return (
    <div className="absolute bottom-36 right-24 z-50 border-xl p-2 pl-16 flex gap-12 items-center justify-center">
      <p
        className="text-base max-w-sm"
        dangerouslySetInnerHTML={{ __html: qrText }}
      />
      <QRCode
        className="flex-shrink-0"
        level="L"
        size={200}
        value={url}
        bgColor="#fff"
        fgColor="#000"
      />
    </div>
  );
}

export default memo(QrCode);
