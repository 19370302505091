import { memo, useMemo } from "react";
import QuoteMark from "./Icons/Quotemark";

function Quote({ quote, cite }) {
  const text = useMemo(() => {
    if (!quote) return;
    return quote.replace(/\n/g, "<br />");
  }, [quote]);

  if (!quote) return <></>;

  return (
    <div className="absolute z-20 inset-0 pointer-events-none flex items-end justify-center ">
      <div className="w-1/3 mb-44 relative">
        <blockquote className="text-4xl leading-relaxed font-light relative">
          <QuoteMark className="absolute -top-6 -left-16" />
          <p dangerouslySetInnerHTML={{ __html: text }} />
          <QuoteMark className="absolute -bottom-24 -right-16 rotate-180" />
        </blockquote>
        {!!cite && (
          <cite className="block mt-8 text-2xl font-light text-tc-primary opacity-100">
            {cite}
          </cite>
        )}
      </div>
    </div>
  );
}

export default memo(Quote);
