export default function QrIcon({ className = "w-full" }) {
  return (
    <svg
      className={className}
      viewBox="0 0 686 569"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="376"
        y="2"
        width="307.527"
        height="565"
        rx="38"
        stroke="white"
        strokeWidth="4"
      />
      <rect
        x="398.76"
        y="45.3867"
        width="262.719"
        height="470.404"
        stroke="white"
        strokeWidth="4"
      />
      <path
        d="M52.4881 375.088C50.1415 376.543 47.0592 375.821 45.6035 373.475L21.8824 335.234C20.4267 332.888 21.149 329.806 23.4956 328.35C25.8422 326.894 28.9245 327.617 30.3802 329.963L51.4656 363.954L85.4569 342.869C87.8035 341.413 90.8858 342.135 92.3414 344.482C93.7971 346.829 93.0748 349.911 90.7282 351.367L52.4881 375.088ZM44.9843 369.698C57.0998 317.994 91.2886 245.163 154.726 194.456C218.431 143.535 311.159 115.323 439.196 152.432L436.413 162.037C311.322 125.782 221.997 153.487 160.97 202.267C99.6749 251.261 66.4521 321.914 54.7206 371.98L44.9843 369.698Z"
        fill="white"
      />
    </svg>
  );
}
