import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectLanguage } from "@/state/selectors/general";

export default function useTranslations(item) {
  const language = useSelector(selectLanguage);

  const fields = useMemo(
    () =>
      item?.translations?.find(
        (t) =>
          t.languages_code === language ||
          t.languages_code?.code === language ||
          t.languages_id === language ||
          t.languages_id?.code === language,
      ),
    [language, item],
  );

  return fields || {};
}
