import { shallowEqual, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import Close from "./Icons/Close";

import Arrow from "@/components/Icons/Arrow";
import { useLocale } from "@/hooks/useLocales";
import useTranslations from "@/hooks/useTranslations";
import { selectCurrentStation } from "@/state/selectors/general";

export default function Modal({ open, closeModal, children }) {
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const { title } = useTranslations(currentStation);
  const backTo = useLocale({ id: "backToStation" });

  return (
    <AnimatePresence>
      {!!open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, ease: "linear" }}
          className="main-modal"
          style={{ zIndex: 9999 }}
        >
          <div className="flex flex-col h-full ">
            <Close
              className={
                "text-tc-secondary h-24 absolute top-6 left-6 z-50 cursor-pointer"
              }
              onClick={closeModal}
            />

            <div className="flex w-full h-full relative">{children}</div>
            {!!currentStation && (
              <motion.div
                whileTap={{ scale: 0.9, originX: 0 }}
                onPointerDown={closeModal}
                className="flex gap-4 items-center text-4xl font-title font-light p-4 absolute bottom-8 left-8 z-50"
              >
                <Arrow direction="left" />
                {backTo} „{title}“
              </motion.div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
