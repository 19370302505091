import { useMemo } from "react";
export const useEnhancedText = ({ string }) => {
  const text = useMemo(() => enhancedText(string), [string]);

  return text;
};

const cleanText = (string) => {
  const regex = /style="[^"]*"/gm;
  return string.replace(regex, "");
};

const withRte = (string) => {
  const regex = /\*([^*]*)\*?/gm; // hello *i am italic*
  return string.replace(regex, "<em>$1</em>");
};

const enhancedText = (string) => {
  if (!string) return "";

  let text = string;
  text = cleanText(text);
  text = withRte(text);

  return text;
};
