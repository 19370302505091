import useTranslations from "@/hooks/useTranslations";
import { getAssetUrl, getMediaType } from "@/lib/assets";

export default function Article({ article }) {
  return (
    <div className="article">
      {article.map(
        (articleModule) =>
          !!articleModule.item && (
            <ArticleModule key={articleModule.id} {...{ articleModule }} />
          ),
      )}
    </div>
  );
}

function TextModule({ articleModule }) {
  const { rte } = useTranslations(articleModule.item);

  return (
    <div
      className="rte rte--article"
      dangerouslySetInnerHTML={{ __html: rte }}
    />
  );
}

function ColumnsModule({ articleModule }) {
  const { column_left, column_right } = useTranslations(articleModule.item);
  return (
    <>
      <div
        className="rte rte--article"
        dangerouslySetInnerHTML={{ __html: column_left }}
      />
      <div
        className="rte rte--article"
        dangerouslySetInnerHTML={{ __html: column_right }}
      />
    </>
  );
}

function MediaModules({ articleModule }) {
  const { item } = articleModule;
  const { images } = item;

  return (
    <>
      {images.map((asset) => (
        <ArticleAsset key={asset.id} {...{ asset }} />
      ))}
    </>
  );
}

function ArticleAsset({ asset }) {
  const { directus_files_id } = asset;
  const type = getMediaType(asset);
  if (type !== "image" || !directus_files_id) return <></>; // not allow in ehibith
  const { id } = directus_files_id;
  return id ? <img src={getAssetUrl(id)} /> : <></>;
}

const modulesTypes = {
  text_modules: TextModule,
  columns_modules: ColumnsModule,
  media_modules: MediaModules,
};

function ArticleModule({ articleModule }) {
  const { collection } = articleModule;
  const Component = modulesTypes[collection] || <></>;

  return (
    <div data-type={collection} className="article__module">
      <Component {...{ articleModule }} />
    </div>
  );
}
