import { shallowEqual, useSelector } from "react-redux";
import Step from "./Step";

import { useStepsScroll } from "@/hooks/useStepsScroll";
import { selectCurrentStation } from "@/state/selectors/general";

export default function Steps({ scrollRef }) {
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const { steps } = currentStation;

  const { onScroll, scrollY } = useStepsScroll({ scrollRef });

  return (
    <>
      <div className="steps-contents" ref={scrollRef} onScroll={onScroll}>
        {steps.map((step, index) => (
          <Step
            key={step.id}
            {...{
              step,
              index,
              scrollY,
            }}
          />
        ))}
      </div>
    </>
  );
}
