import { useMemo } from "react";
import { useSelector } from "react-redux";

import i18n from "@/lib/i18n";
import { selectLanguage } from "@/state/selectors/general";

export const useLocale = ({ id }) => {
  const locale = useSelector(selectLanguage);

  const label = useMemo(() => i18n({ id, locale }), [id, locale]);

  return label;
};
