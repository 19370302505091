// import { memo } from "react";
// // import { useRef } from "react";
// import Quote from "./Quote";

// import useTranslations from "@/hooks/useTranslations";
// import { getAssetUrl } from "@/lib/assets";

// function StepMedia({ step }) {
//   const { quote, quote_caption: cite } = useTranslations(step);
//   const { slideImage } = step;
//   const { id, width, height } = slideImage;

//   return (
//     <div className="step-media">
//       <Quote {...{ quote, cite }} />
//       <div className="media-wrapper" data-layout={quote ? "with-quote" : ""}>
//         <img
//           src={getAssetUrl(id)}
//           width={width}
//           height={height}
//           className="m-auto w-full h-full object-contain"
//         />
//       </div>
//     </div>
//   );
// }

// export default memo(StepMedia);

import { memo, useEffect } from "react";
import { useRef } from "react";
import Quote from "./Quote";

import useTranslations from "@/hooks/useTranslations";
import { getAssetUrl } from "@/lib/assets";

function StepMedia({ step }) {
  const { quote, quote_caption: cite } = useTranslations(step);
  const { slideImage } = step;
  const { id, width, height } = slideImage;

  const canvas = useRef();

  useEffect(() => {
    const context = canvas.current.getContext("2d");
    context.filter = "url(#customfilter)";
    const image = new Image();
    image.src = getAssetUrl(id);

    image.onload = () => {
      context.drawImage(image, 0, 0, width, height);
    };
  }, []);

  return (
    <div className="step-media">
      <Quote {...{ quote, cite }} />
      <div className="media-wrapper" data-layout={quote ? "with-quote" : ""}>
        <canvas
          ref={canvas}
          width={width}
          height={height}
          className="m-auto w-full h-full object-contain"
        />
      </div>
    </div>
  );
}

export default memo(StepMedia);
