import FetchRetry from "fetch-retry";
import config from "./config";

const fetchRetry = FetchRetry(fetch);

// http://localhost:3001/api/exhibit/stations

export const PAGES = "pages";
export const STATIONS = "stations";
export const CHAPTERS = "chapters";
export const STEPS = "steps";

export const request = ({
  method = "GET",
  url,
  body,
  reqJson = true,
  resJson = true,
  retries = 0,
}) => {
  return new Promise((resolve, reject) => {
    fetchRetry(url, {
      retryOn: (attempt, error, response) => {
        return error !== null || response.status >= 400;
      },
      retryDelay: 5000,
      retries,
      method,
      headers: {
        "Content-Type": reqJson ? "application/json" : undefined,
      },
      body: reqJson ? JSON.stringify(body) : body,
    })
      .then((response) => {
        if (resJson) return response.json();
        return response;
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
};

export const getMesoConnectConfig = () => {
  return request({ url: config.mesoConnectConfigUrl });
};

export const getAppData = async () => {
  if (config.contentFetchEnabled) return getLiveData();

  const data = await request({
    url: `${config.cmsLocalHost}/${config.localDataRoute}`,
  });

  return data.exhibit;
};

export const getLiveData = async () => {
  const req = await request({
    url: config.apiRoute,
  });

  return req.data;
};
