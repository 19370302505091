export default function Marker(props) {
  return (
    <svg {...props} viewBox="0 0 88 57" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.285156 56.5684L0.285151 -0.000182152L28.5694 28.2841L0.285156 56.5684Z"
        fill="currentColor"
      />
      <line
        x1="19"
        y1="28.3"
        x2="88"
        y2="28.3"
        stroke="currentColor"
        strokeWidth="5.4"
      />
    </svg>
  );
}
