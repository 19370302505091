import { useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useElementScroll } from "framer-motion";

import config from "@/lib/config";
import { selectCurrentScrollIndex } from "@/state/selectors/general";
import { setCurrentScrollIndex } from "@/state/slices/general";

export const useStepsScroll = ({ scrollRef }) => {
  const dispatch = useDispatch();

  const { scrollY } = useElementScroll(scrollRef);

  const currentScrollIndex = useSelector(
    selectCurrentScrollIndex,
    shallowEqual,
  );

  const onScroll = useCallback(() => {
    const nextIndex = Math.round(scrollRef.current.scrollTop / config.height);
    if (nextIndex !== currentScrollIndex)
      dispatch(setCurrentScrollIndex(nextIndex));
  }, [currentScrollIndex]);

  return { onScroll, scrollY };
};
