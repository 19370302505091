import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import { selectShowMainMenu } from "@/state/selectors/general";
import { setToggleMenu } from "@/state/slices/general";

export default function ToggleMainMenu() {
  const dispatch = useDispatch();
  const showMainMenu = useSelector(selectShowMainMenu, shallowEqual);

  function handleClick() {
    dispatch(setToggleMenu());
  }

  return (
    <MenuButton
      isOpen={showMainMenu}
      onClick={handleClick}
      className="cursor-pointer mt-8 mb-2 text-tc-secondary"
    />
  );
}

const MenuButton = ({
  isOpen = false,
  width = 52,
  height = 52,
  spacing = 1.4,
  strokeWidth = 6,
  color = "currentColor",
  transition = null,
  ...props
}) => {
  const variant = isOpen ? "opened" : "closed";

  const lineProps = {
    stroke: color,
    strokeWidth: strokeWidth,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
  };

  const vbHeight = 4;
  const vbWidth = (vbHeight * width) / height;

  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: spacing,
    },
  };
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -spacing,
    },
  };

  return (
    <motion.svg
      viewBox={`0 0 ${vbWidth} ${vbHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      {...props}
      whileTap={{ scale: 0.8 }}
    >
      <motion.line
        x1="0"
        x2={vbWidth}
        y1={0 * spacing}
        y2={0 * spacing}
        variants={top}
        {...lineProps}
      />
      <motion.line
        x1="0"
        x2={vbWidth}
        y1={1 * spacing}
        y2={1 * spacing}
        variants={center}
        {...lineProps}
      />
      <motion.line
        x1="0"
        x2={vbWidth}
        y1={2 * spacing}
        y2={2 * spacing}
        variants={bottom}
        {...lineProps}
      />
    </motion.svg>
  );
};
