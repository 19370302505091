import { useCallback, useEffect } from "react";

const KEYCODE_LEFT_ARROW = 37;
const KEYCODE_UP_ARROW = 38;
const KEYCODE_RIGHT_ARROW = 39;
const KEYCODE_DOWN_ARROW = 40;
const KEYCODE_ESC = 27;

export default function useKeyboardNav({ goPrev, goNext, onClose, disabled }) {
  const keyDownCallback = useCallback(
    (event) => {
      if (disabled) return;
      if (event.target.dataset.nokeyboard) return;
      if (
        event.keyCode === KEYCODE_LEFT_ARROW ||
        event.keyCode === KEYCODE_UP_ARROW
      ) {
        event.preventDefault();
        return goPrev(event);
      }
      if (
        event.keyCode === KEYCODE_RIGHT_ARROW ||
        event.keyCode === KEYCODE_DOWN_ARROW
      ) {
        event.preventDefault();
        return goNext(event);
      }
      if (event.keyCode === KEYCODE_ESC && typeof onClose === "function")
        return onClose(event);
    },
    [goPrev, goNext, onClose, disabled],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyDownCallback);
    return () => document.removeEventListener("keydown", keyDownCallback);
  }, [keyDownCallback]);
}
