import Connection from "meso-connect";
import config from "./config";

import {
  publishedMessage,
  receivedMessage,
  setConnected,
  setDisconnected,
  setSubscribed,
} from "@/state/slices/mqtt";
import { store } from "@/state/store";

let connection;

const isConnected = () => store.getState().mqtt.connected;
const isSubscribed = () => store.getState().mqtt.subscribed;

export const initConnection = (mesoConnectConfig) => {
  const { brokerOverride, deviceId, brokerUsername, brokerPassword } = config;
  const { protocol, address, port } = brokerOverride;
  const hasBrokerOverride = protocol && address && port;

  connection = new Connection(
    mesoConnectConfig,
    deviceId,
    hasBrokerOverride ? brokerOverride : null,
    {
      connectTimeout: 1000,
      username: brokerUsername,
      password: brokerPassword,
    },
  );

  connection.on("connect", handleConnected);
  connection.on("close", handleDisconnected);
  connection.on("offline", handleDisconnected);
  connection.on("message", handleMessageReceived);
  connection.on("validation-error", handleError);
  connection.on("connection-error", handleError);

  window.onbeforeunload = endConnection;
  connection.init();
};

export const endConnection = () => {
  if (isConnected()) {
    connection.end().then(() => store.dispatch(setDisconnected()));
  }
};

export const publish = (property, value) => {
  if (isConnected()) {
    connection
      .publish(property, value)
      .then(() => store.dispatch(publishedMessage({ property, value })));
  }
};

export const subscribe = () => {
  if (isConnected()) {
    const topics = Object.values(config.mqttSubscribe);
    connection.subscribe(topics).then(() => store.dispatch(setSubscribed()));
  }
};

const handleConnected = () => {
  store.dispatch(setConnected());
  if (!isSubscribed()) subscribe();
};

const handleDisconnected = () => {
  store.dispatch(setDisconnected());
};

const handleError = (error) => {
  console.error(error);
};

const handleMessageReceived = ({ property, value }) => {
  store.dispatch(receivedMessage({ property, value }));
};
