import { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import MarkerIcon from "./Icons/Marker";

import LanguageSwitch from "@/components/LanguageSwitch";
import { StationsMenu } from "@/components/StationsMenu";
import ToggleMainMenu from "@/components/ToggleMainMenu";
import { useEnhancedText } from "@/hooks/useCleanText";
import useTranslations from "@/hooks/useTranslations";
import config from "@/lib/config";
import {
  selectCurrentChapter,
  selectCurrentStation,
  selectMarkerPos,
  selectShowMainMenu,
  useIsCurrentChapter,
  useIsCurrentStep,
  useParamSelector,
} from "@/state/selectors/general";
import {
  setCloseMenu,
  setMarkerPos,
  setOpenMenu,
} from "@/state/slices/general";

const scrollModule = config.height;

export default function MainMenu({ goTo }) {
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const showMainMenu = useSelector(selectShowMainMenu, shallowEqual);
  const { related_chapters: chapters } = currentStation;

  return (
    <>
      <ChapterTitles {...{ chapters }} />

      <div className="main-menu-blanket" data-active={showMainMenu} />

      <div className="main-menu">
        <StationsMenu />

        <div
          className="chapters-menu"
          data-type={chapters.length < 4 ? "force-compact" : ""}
        >
          <Marker />
          {chapters.map((chapter, chapterIndex) => (
            <Chapter key={chapter.id} {...{ chapter, chapterIndex, goTo }} />
          ))}
        </div>

        <ToggleMainMenu />
        <LanguageSwitch />
      </div>
    </>
  );
}

function ChapterTitles({ chapters }) {
  return (
    <div>
      {chapters.map((chapter) => (
        <ChapterTitle key={chapter.id} {...{ chapter }} />
      ))}
    </div>
  );
}

function ChapterTitle({ chapter }) {
  const currentChapter = useSelector(selectCurrentChapter, shallowEqual);
  const { id, title: label } = chapter;
  const { title, subtitle } = useTranslations(chapter);
  const chapterTitle = useEnhancedText({ string: title || label });

  return (
    <div
      className="main-menu__current-chapter"
      data-active={currentChapter.id === id}
    >
      <div
        className="main-menu__current-chapter__title"
        dangerouslySetInnerHTML={{ __html: chapterTitle }}
      />
      {!!subtitle && (
        <div className="main-menu__current-chapter__subtitle">{subtitle}</div>
      )}
    </div>
  );
}

function Chapter({ chapter, chapterIndex, goTo }) {
  const { related_steps: steps, title: label } = chapter;
  const dispatch = useDispatch();
  const showMainMenu = useSelector(selectShowMainMenu, shallowEqual);
  const station = useSelector(selectCurrentStation, shallowEqual);
  const isCurrent = useParamSelector(useIsCurrentChapter, {
    chapter: chapter.id,
  });

  const { title, subtitle } = useTranslations(chapter);
  const chapterTitle = useEnhancedText({ string: title || label });

  const chapterLabelVariants = useMemo(
    () => ({
      hidden: { opacity: 0, x: -20 },
      show: {
        opacity: 1,
        x: 0,
        transition: {
          delay: 0.3 + 0.1 * chapterIndex,
          duration: 0.3,
        },
      },
    }),
    [chapterIndex],
  );

  function handleClickItem() {
    const { related_steps } = chapter;
    const { steps } = station;
    const firstStepId = related_steps[0];
    const stepIndex = steps.findIndex((s) => s.id === firstStepId);
    goTo(stepIndex * scrollModule, "auto");
    dispatch(setCloseMenu());
  }

  function handleMenuShortcut() {
    if (!showMainMenu) return dispatch(setOpenMenu());
    handleClickItem();
  }

  return (
    <>
      <div className="chapter-ref">
        <div className="chapter-tick" onClick={handleMenuShortcut} />

        <AnimatePresence>
          {showMainMenu && (
            <motion.div
              variants={chapterLabelVariants}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="chapter-ref__label"
              onClick={handleClickItem}
              data-current={isCurrent}
            >
              <div
                className="chapter-ref__label__title"
                dangerouslySetInnerHTML={{ __html: chapterTitle }}
              />
              {!!subtitle && (
                <div className="chapter-ref__label__subtitle">, {subtitle}</div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {steps.map((step) => (
        <Step key={step} {...{ step }} />
      ))}
    </>
  );
}

function Step({ step }) {
  const dispatch = useDispatch();

  const active = useParamSelector(useIsCurrentStep, {
    step,
  });

  const el = useRef(null);

  useEffect(() => {
    if (active) dispatch(setMarkerPos(el.current.offsetTop));
  }, [active]);

  return <div className="step-tick" data-active={active} ref={el} />;
}

function Marker() {
  const markerPos = useSelector(selectMarkerPos, shallowEqual);

  return (
    <div className="tick-marker" style={{ top: markerPos }}>
      <MarkerIcon className="text-tc-secondary" />
    </div>
  );
}
