import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { motion } from "framer-motion";

import { selectCurrentStep, selectIdleMode } from "@/state/selectors/general";

export default function Copyright() {
  const currentStep = useSelector(selectCurrentStep, shallowEqual);
  const isIdle = useSelector(selectIdleMode, shallowEqual);
  const { slideImage } = currentStep;
  const { description: copyright } = slideImage;

  const [showCopyright, setShowCopyright] = useState(false);

  function handleClick() {
    setShowCopyright((c) => !c);
  }

  useEffect(() => {
    if (isIdle) return setShowCopyright(false);
  }, [isIdle]);

  if (!copyright) return <></>;

  return (
    <motion.div
      className="media-copy-wrapper"
      onPointerDown={handleClick}
      data-active={showCopyright}
      whileTap={{ scale: 0.9 }}
    >
      <div className="media-copy-btn">c</div>
      <div
        className={`media-copy-box ${
          showCopyright ? "scale-x-100" : "scale-x-0"
        }`}
      >
        <div dangerouslySetInnerHTML={{ __html: copyright }} />
      </div>
    </motion.div>
  );
}
