import { shallowEqual, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

export const useParamSelector = (selector, ...params) =>
  useSelector((state) => selector(state, ...params), shallowEqual);

function createParameterSelector(selector) {
  return (_, params) => selector(params);
}

const getCurrentStation = (stations, identifier) => {
  return stations.find(
    (s) => parseInt(s.station_identifier) === parseInt(identifier),
  );
};

const getChapterParam = createParameterSelector((params) => params.chapter);

const getStepParam = createParameterSelector((params) => params.step);

export const selectIdleMode = ({ general }) => general.idleMode;
export const selectStations = ({ general }) => general.stations;
export const selectScale = ({ general }) => general.scale;
export const selectFilter = ({ general }) => general.filter;
export const selectLanguage = ({ general }) => general.language;
export const selectLanguages = ({ general }) => general.languages;
export const selectCurrentStationIndex = ({ general }) =>
  general.currentStation;
export const selectCurrentChapterIndex = ({ general }) =>
  general.currentChapter;
export const selectCurrentStepIndex = ({ general }) => general.currentStep;
export const selectShowMainMenu = ({ general }) => general.showMainMenu;
export const selectShowStationsMenu = ({ general }) => general.showStationsMenu;
export const selectShowImpressum = ({ general }) => general.showImpressum;
export const selectManualSwitch = ({ general }) => !!general.manualSwitch;
export const selectScrollbehavior = (state) => state.general.scrollbehavior;
export const selectMarkerPos = (state) => state.general.markerPos;
export const selectCurrentScrollIndex = (state) =>
  state.general.currentScrollIndex;

export const selectImpressum = (state) => state.general.impressum;

export const selectCurrentStation = createSelector(
  ({ general }) => general.stations,
  ({ general }) => general.currentStation,
  (stations, currentStation) => getCurrentStation(stations, currentStation),
);

export const selectCurrentStep = createSelector(
  selectCurrentStation,
  ({ general }) => general.currentScrollIndex,
  (station, currentScrollIndex) => {
    return station?.steps?.[currentScrollIndex];
  },
);

export const selectCurrentChapter = createSelector(
  selectCurrentStation,
  selectCurrentStep,
  (station, currentStep) => {
    const { chapterID } = currentStep;
    if (!chapterID) return null;
    return station.related_chapters?.find((c) => c.id === chapterID);
  },
);

export const selectInlineNav = createSelector(
  selectCurrentStation,
  selectCurrentScrollIndex,

  (station, currentScrollIndex) => {
    const { steps } = station;

    const hasNext = currentScrollIndex < steps.length - 1;
    const hasPrev = currentScrollIndex > 0;

    return { hasNext, hasPrev };
  },
);

export const useIsCurrentChapter = createSelector(
  [selectCurrentChapter, getChapterParam],
  (currentChapter, chapter) => {
    return currentChapter.id === chapter;
  },
);

export const useIsCurrentStep = createSelector(
  [selectCurrentStep, getStepParam],
  (currentStep, step) => currentStep.id === step,
);

export const useScrollBehavior = createSelector(
  [useIsCurrentStep, ({ general }) => general.scrollbehavior],
  (isCurrentStep, scrollbehavior) => {
    return isCurrentStep ? scrollbehavior : "smooth";
  },
);
