const locales = {
  "de-DE": {
    backToStation: "Zurück zu",
    stationSelectorTitle: "Alle Stationen der Ausstellung im Schnellzugriff",
    restartChapter: "Zum Kapitelstart",
    qrText: "Scannen Sie den QR-Code um die Rede in voller Länge zu hören",
  },
  "en-US": {
    backToStation: "Back to",
    stationSelectorTitle: "Exhibit stations overview",
    restartChapter: "Restart chapter",
    qrText: "Scan the QR code<br/>to hear the speech<br/>in full lenght",
  },
};

const i18n = ({ id, locale }) => {
  // console.log(locales?.[locale], id);
  return locales?.[locale]?.[id] || `Missing translation for ${id}`;
};

export default i18n;
