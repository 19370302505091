import { memo, useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import Stations from "@/components/Icons/Stations";
import { useLocale } from "@/hooks/useLocales";
import useTranslations from "@/hooks/useTranslations";
import {
  selectCurrentStation,
  selectStations,
} from "@/state/selectors/general";
import { setShowStationsMenu, setStation } from "@/state/slices/general";

function StationsSelector() {
  const dispatch = useDispatch();
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const stations = useSelector(selectStations, shallowEqual);
  const wait = useRef();
  const stationSelectorTitle = useLocale({ id: "stationSelectorTitle" });

  const stationsLeft = useMemo(
    () => stations.slice(0, 3)?.reverse(),
    [stations],
  );

  const stationsRight = useMemo(() => stations.slice(3), [stations]);

  function onClickStation(stationIdentifier) {
    if (wait.current) clearTimeout(wait.current);

    if (
      parseInt(currentStation.station_identifier) ===
      parseInt(stationIdentifier)
    ) {
      return dispatch(setShowStationsMenu(false));
    }

    dispatch(setStation(stationIdentifier));

    wait.current = setTimeout(() => {
      dispatch(setShowStationsMenu(false));
    }, 750);
  }

  useEffect(() => {
    return () => {
      if (wait.current) clearTimeout(wait.current);
    };
  }, []);

  return (
    <div className="stations-menu">
      <div className="stations-group">
        {stationsLeft.map((station, index) => (
          <Station
            key={station.id}
            {...{ station, index, left: true, onClickStation }}
          />
        ))}
      </div>

      <div className="station-graphics">
        <h2>{stationSelectorTitle}</h2>
        <Stations
          onClickStation={onClickStation}
          currentIndex={currentStation}
          width="90rem"
        />
      </div>

      <div className="stations-group items-start">
        {stationsRight.map((station, index) => (
          <Station key={station.id} {...{ station, index, onClickStation }} />
        ))}
      </div>
    </div>
  );
}

function Station({ station, index, left, onClickStation }) {
  const { station_identifier } = station;
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const { title } = useTranslations(station);

  const active =
    parseInt(currentStation.station_identifier) ===
    parseInt(station_identifier);

  function handleClick() {
    onClickStation(parseInt(station_identifier));
  }

  return (
    <div
      onPointerDown={handleClick}
      className="stations-group__item"
      data-active={active}
      data-align={left ? "left" : "right"}
      style={{
        transform: left
          ? `translateX(${index * 2}em)`
          : `translateX(-${index * 2}em)`,
      }}
    >
      <motion.div
        className="stations-group__item-title"
        whileTap={{ scale: 0.9 }}
      >
        <span>{title}</span>
      </motion.div>
    </div>
  );
}

export default memo(StationsSelector);
