import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Modal from "./Modal";
import StationsSelector from "./StationsSelector";

import useTranslations from "@/hooks/useTranslations";
import {
  selectCurrentStation,
  selectShowStationsMenu,
} from "@/state/selectors/general";
import { setShowStationsMenu } from "@/state/slices/general";

export const StationsMenu = () => {
  const dispatch = useDispatch();
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const { station_identifier, title: label } = currentStation;

  const { title } = useTranslations(currentStation);
  const stationTitle = title || label;

  function showStationMenu() {
    dispatch(setShowStationsMenu(true));
  }

  return (
    <div className="station-ref" onClick={showStationMenu}>
      <div className="station-ref__identifier">{station_identifier}</div>
      <div className="station-ref__title">{stationTitle}</div>
    </div>
  );
};

export const StationsMenuExpanded = () => {
  const dispatch = useDispatch();
  const showStationsMenu = useSelector(selectShowStationsMenu, shallowEqual);

  function onCloseModal() {
    dispatch(setShowStationsMenu(false));
  }

  return (
    <Modal open={showStationsMenu} closeModal={onCloseModal}>
      <StationsSelector />
    </Modal>
  );
};
