import { configureStore } from "@reduxjs/toolkit";
import loggerMiddleware from "./middleware/logger";
import general from "./slices/general";
import mqtt from "./slices/mqtt";

export const store = configureStore({
  reducer: { general, mqtt },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});
