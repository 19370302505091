import { memo } from "react";

import { useEnhancedText } from "@/hooks/useCleanText";
import useTranslations from "@/hooks/useTranslations";

function TextBox({ step }) {
  const { slideImage } = step;
  const { text } = useTranslations(step);

  return (
    <>
      <div
        className="texts-step-box__text"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <Caption {...{ slideImage }} />
    </>
  );
}

export default memo(TextBox);

function Caption({ slideImage }) {
  const { caption } = useTranslations(slideImage);
  const cleanCaption = useEnhancedText({ string: caption });

  if (!cleanCaption) return <></>;

  return (
    <div
      className="step-caption"
      dangerouslySetInnerHTML={{ __html: cleanCaption }}
    />
  );
}
