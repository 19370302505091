import { useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import { selectLanguage, selectLanguages } from "@/state/selectors/general";
import { setLanguage } from "@/state/slices/general";

export default function LanguageSwitch() {
  const languages = useSelector(selectLanguages, shallowEqual);

  return (
    <div className="langs">
      {languages.map((lang) => (
        <Lang key={lang.code} {...{ lang }} />
      ))}
    </div>
  );
}

function Lang({ lang }) {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage, shallowEqual);
  const active = useMemo(() => language === lang.code, [language, lang]);

  function switchLang() {
    if (active) return;
    dispatch(setLanguage(lang.code));
  }

  return (
    <motion.div
      onClick={switchLang}
      className="lang"
      data-active={active}
      whileTap={{ scale: 0.9 }}
    >
      {lang.name}
    </motion.div>
  );
}
