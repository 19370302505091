import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { selectImpressum } from "../state/selectors/general";
import Article from "./Article";
import Modal from "./Modal";

import { selectShowImpressum } from "@/state/selectors/general";
import { setShowImpressum } from "@/state/slices/general";

export const ImpressumBtn = () => {
  const dispatch = useDispatch();

  function showImpressum() {
    dispatch(setShowImpressum(true));
  }

  return (
    <motion.div
      className="impressum-link"
      onClick={showImpressum}
      whileTap={{ scale: 0.9 }}
    >
      <span>i</span>
    </motion.div>
  );
};

export const ImpressumModal = () => {
  const dispatch = useDispatch();
  const showImpressum = useSelector(selectShowImpressum, shallowEqual);

  function onCloseModal() {
    dispatch(setShowImpressum(false));
  }

  return (
    <Modal open={showImpressum} closeModal={onCloseModal}>
      <div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key="menu"
        transition={{ duration: 0.5, ease: "linear" }}
        className={"switch-motion-wrapper"}
      >
        <ImpressumContent />
      </div>
    </Modal>
  );
};

export const ImpressumContent = () => {
  const impressum = useSelector(selectImpressum);

  const { article } = impressum || {};

  if (!impressum) return <></>;

  return (
    <div className="impressum-wrapper">
      <Article {...{ article }} />
    </div>
  );
};
