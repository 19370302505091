import { memo } from "react";
import { motion, useTransform } from "framer-motion";
import TextBox from "./TextBox";

import useTranslations from "@/hooks/useTranslations";
import config from "@/lib/config";

function StepContent({ step, scrollY, index }) {
  const { text } = useTranslations(step);

  const opacity = useTransform(
    scrollY,
    [
      index * config.height - 300,
      index * config.height,
      index * config.height + 300,
    ],
    [0, 1, 0],
  );

  if (!text) return <></>;

  return (
    <motion.div style={{ opacity }} className="texts-step-content__inner">
      <div className="texts-step-box">
        <TextBox {...{ step }} />
      </div>
    </motion.div>
  );
}

export default memo(StepContent);
