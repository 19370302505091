import { throttle } from "lodash";
import config from "./config";

import { enterIdleMode, exitIdleMode } from "@/state/slices/general";
import { store } from "@/state/store";

let timeout;

export const init = () => {
  document.addEventListener("click", resetIdleTimeoutThrottled);
  document.addEventListener("touchstart", resetIdleTimeoutThrottled);
  document.addEventListener("keydown", resetIdleTimeoutThrottled);
  document.addEventListener("wheel", resetIdleTimeoutThrottled);

  resetIdleTimeout();
};

export const remove = () => {
  document.removeEventListener("click", resetIdleTimeoutThrottled);
  document.removeEventListener("touchstart", resetIdleTimeoutThrottled);
  document.removeEventListener("keydown", resetIdleTimeoutThrottled);
  document.removeEventListener("wheel", resetIdleTimeoutThrottled);
};

const resetIdleTimeout = () => {
  const state = store.getState();
  const time = config.idleTimeout;
  const isIdle = state.general.idleMode;
  if (time <= 0 || time === undefined) return;

  if (timeout) clearTimeout(timeout);
  if (isIdle) store.dispatch(exitIdleMode());

  timeout = setTimeout(() => {
    store.dispatch(enterIdleMode());
  }, time * 1000);
};

const resetIdleTimeoutThrottled = throttle(resetIdleTimeout, 5000);
