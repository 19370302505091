import { useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectIdleMode } from "../state/selectors/general";
import { ImpressumBtn, ImpressumModal } from "./Impressum";
import Steps from "./Steps";

import Copyright from "@/components/Copyright";
import MainMenu from "@/components/MainMenu";
import { StationsMenuExpanded } from "@/components/StationsMenu";
import StepsMenu from "@/components/StepsMenu";
import { selectCurrentStation } from "@/state/selectors/general";

export default function StationWrapper() {
  const scrollRef = useRef(null);
  const currentStation = useSelector(selectCurrentStation, shallowEqual);
  const isIdle = useSelector(selectIdleMode, shallowEqual);

  function goTo(top, behavior, method = "scrollTo") {
    scrollRef.current[method]({
      behavior,
      top,
    });
  }

  useEffect(() => {
    goTo(0, "auto");
  }, [currentStation]);

  useEffect(() => {
    if (!isIdle) return;
    goTo(0, "auto");
  }, [isIdle]);

  return (
    <>
      <Steps {...{ scrollRef }} />
      <MainMenu {...{ goTo }} />
      <StepsMenu {...{ goTo }} />
      <Copyright />
      <StationsMenuExpanded />
      <ImpressumBtn />
      <ImpressumModal />
    </>
  );
}
