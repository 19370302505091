import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { selectScale } from "@/state/selectors/general";
import { setScale } from "@/state/slices/general";

export default function AutoscaleLayout({
  width,
  height,
  className,
  children,
}) {
  const scale = useSelector(selectScale, shallowEqual);
  const dispatch = useDispatch();
  const aspectRatio = width / height;

  const calculateScale = () => {
    let scaleFactor;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowAspectRatio = windowWidth / windowHeight;

    if (windowAspectRatio > aspectRatio) {
      scaleFactor = windowHeight / height;
    } else {
      scaleFactor = windowWidth / width;
    }

    const scaleFactorRounded = Math.round(scaleFactor * 10000) / 10000;

    dispatch(setScale(scaleFactorRounded));
  };

  const initResizeEventHandler = () => {
    window.addEventListener("resize", calculateScale);
    return () => window.removeEventListener("resize", calculateScale);
  };

  useEffect(calculateScale, []);
  useEffect(initResizeEventHandler, []);

  return (
    <div
      className={`${className} bg-bc-primary`}
      style={{
        position: "absolute",
        width: `${width}px`,
        height: `${height}px`,
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%) scale(${scale})`,
        transformOrigin: "center center",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
}
