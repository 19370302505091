import { createSlice } from "@reduxjs/toolkit";

import { getAppData } from "@/lib/api";
import config from "@/lib/config";

const { actions, reducer } = createSlice({
  name: "general",
  initialState: {
    idleMode: false,
    dataLoadingState: "idle",
    stations: [],
    impressum: null,
    scale: 1,
    filter: "url(#customfilter)",
    language: "de-DE",
    languages: [
      { code: "de-DE", name: "DE" },
      { code: "en-US", name: "EN" },
    ],
    defaultStationId: config.defaultStationId,
    currentStation: config.defaultStationId,
    currentChapter: null,
    currentStep: null,
    showMainMenu: false,
    showStationsMenu: false,
    showImpressum: false,
    manualSwitch: null,
    scrollbehavior: "smooth",
    markerPos: 0,
    currentScrollIndex: 0,
  },
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },

    setCurrentScrollIndex(state, { payload }) {
      state.currentScrollIndex = payload;
    },

    setStation(state, { payload }) {
      state.currentStation = payload;
      state.currentScrollIndex = 0;
    },

    setShowStationsMenu(state, { payload }) {
      state.showStationsMenu = payload;
      state.showMainMenu = false;
    },

    setShowImpressum(state, { payload }) {
      state.showStationsMenu = false;
      state.showMainMenu = false;
      state.showImpressum = payload;
    },

    setOpenMenu(state) {
      state.showMainMenu = true;
    },
    setCloseMenu(state) {
      state.showMainMenu = false;
    },
    setToggleMenu(state) {
      state.showMainMenu = !state.showMainMenu;
    },
    setScale(state, action) {
      state.scale = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },

    setMarkerPos(state, action) {
      state.markerPos = action.payload;
    },

    enterIdleMode(state) {
      state.currentStation = state.defaultStationId;
      state.currentScrollIndex = 0;
      state.idleMode = true;
      state.showMainMenu = false;
      state.showStationsMenu = false;
      state.showImpressum = false;
      state.manualSwitch = null;
      state.language = "de-DE";
    },

    exitIdleMode(state) {
      state.idleMode = false;
    },

    dataLoading(state) {
      state.dataLoadingState = "pending";
    },

    dataReceived(state, { payload }) {
      if (state.dataLoadingState === "pending") {
        state.dataLoadingState = "idle";
        const { stations, impressum } = payload;
        state.stations = stations;
        state.impressum = impressum;

        state.currentStation = state.defaultStationId;
        state.currentScrollIndex = 0;
      }
    },
  },
});

export const {
  setLanguage,
  setStation,
  setShowStationsMenu,
  setShowImpressum,
  setCurrentScrollIndex,
  setOpenMenu,
  setCloseMenu,
  setToggleMenu,
  setScale,
  setFilter,
  setMarkerPos,
  enterIdleMode,
  exitIdleMode,
  dataLoading,
  dataReceived,
} = actions;

export const fetchData = () => async (dispatch) => {
  dispatch(dataLoading());
  const data = await getAppData();
  dispatch(dataReceived(data));
};

export default reducer;
