const rotations = {
  down: "180deg",
  up: "0deg",
  left: "-90deg",
  right: "90deg",
};

export default function Arrow({
  colorClass = "text-tc-secondary",
  sizeClass = "w-arrow h-arrow",
  direction = "right",
  onClick = () => {},
}) {
  return (
    <svg
      viewBox="0 0 94 94"
      fill="none"
      className={`${colorClass} ${sizeClass} cursor-pointer`}
      onClick={onClick}
      style={{
        transform: `rotate(${rotations[direction]})`,
      }}
    >
      <path d="M90 51L47 8L3.99999 51" stroke="currentColor" strokeWidth="10" />
    </svg>
  );
}
